import { TaskResponse } from "util/typesAlt";
import { emptyToUndefined } from "util/util";

const TaskResponseForm = ({
  response,
  handleResponseChange,
}: {
  response: TaskResponse;
  handleResponseChange: (response: TaskResponse) => void;
}) => {
  const handleInputChange = (name: string, value: string | boolean) => {
    handleResponseChange({ ...response, [name]: emptyToUndefined(value) });
  };

  return (
    <form className="text-gray-500">
      <div className="mt-3">
        <label>
          Media Description
          <textarea
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            value={response.mediaDescription || ""}
            onChange={(event) =>
              handleInputChange("mediaDescription", event.target.value)
            }
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Text
          <textarea
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            value={response.text || ""}
            onChange={(event) => handleInputChange("text", event.target.value)}
          />
        </label>
      </div>
    </form>
  );
};

export default TaskResponseForm;
