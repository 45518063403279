import { TaskFeedback } from "util/typesAlt";
import { emptyToUndefined } from "util/util";

const TaskFeedbackForm = ({
  feedback,
  handleFeedbackChange,
}: {
  feedback: TaskFeedback;
  handleFeedbackChange: (feedback: TaskFeedback) => void;
}) => {
  const handleInputChange = (name: string, value: string | number) => {
    handleFeedbackChange({ ...feedback, [name]: emptyToUndefined(value) });
  };

  return (
    <form className="text-gray-500">
      <div className="mt-3">
        <label>
          Text
          <textarea
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            value={feedback.text || ""}
            rows={3}
            onChange={(event) => handleInputChange("text", event.target.value)}
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Achievements
          <input
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            type="text"
            value={feedback.achievements || ""}
            onChange={(event) =>
              handleInputChange("achievements", event.target.value)
            }
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Points
          <input
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 ml-1 w-10"
            type="number"
            value={
              feedback.points === undefined ? "" : feedback.points.toString()
            }
            onChange={(event) =>
              handleInputChange("points", parseFloat(event.target.value))
            }
          />
        </label>
      </div>
    </form>
  );
};

export default TaskFeedbackForm;
