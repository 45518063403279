import { useEffect, useState } from "react";

export type Authorization = "guest" | "user" | "admin";

export const useAuth = () => {
  const [auth, setAuth] = useState<Authorization>("guest");

  const submitPassword = (password: string, showAlert: boolean = false) => {
    const userPassword = process.env.REACT_APP_USER_PASSWORD;
    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    let alertMessage = "Password incorrect";
    switch (password) {
      case adminPassword:
        setAuth("admin");
        alertMessage = "Admin recognized";
        break;
      case userPassword:
        setAuth("user");
        alertMessage = "Hi Andrea! :-)";
        break;
      default:
        setAuth("guest");
    }

    localStorage.setItem("password", password);
    if (showAlert) {
      alert(alertMessage);
    }
  };

  useEffect(() => {
    const savedPassword = localStorage.getItem("password");
    if (savedPassword === null) {
      console.log("No saved password found, setting auth to 'guest'");
      setAuth("guest");
      return;
    }
    console.log("Found saved password");
    submitPassword(savedPassword);
  }, []);

  return { auth, submitPassword };
};
