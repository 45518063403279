import { useState } from "react";
import axios from "axios";

import { RequestStatus } from "util/typesAlt";

export const useSendPrompt = (): {
  promptStatus: RequestStatus;
  sendPrompt: (message?: string, notTest?: boolean) => Promise<void>;
} => {
  const [promptStatus, setPromptStatus] = useState<RequestStatus>(null);

  const sendPrompt = async (message?: string, notTest: boolean = false) => {
    const url = `${process.env.REACT_APP_BEHAVE_API_URL}/send-prompt`;
    message = message !== undefined && message.length > 0 ? message : undefined;
    try {
      setPromptStatus("waiting");
      await axios.post(url, {
        message,
        notTest,
      });
      setPromptStatus("success");
    } catch (error) {
      console.error("failed to send prompt", error);
      setPromptStatus("error");
    }
  };
  return { promptStatus, sendPrompt };
};
