import { useState } from "react";
import axios from "axios";

import { RequestStatus, Task } from "util/typesAlt";

const formatTaskData = (task: Task) => {
  const data = {
    // Note that 'id' will be overwritten by api before task creation if 'changeMode' is 'create'
    // '|| undefined' ensures that empty strings will be converted to 'undefined'
    id: task.id,
    exclude: task.about?.exclude ? "y" : undefined,
    created_datetime: task.createdDatetime || undefined,
    color: task.about?.color || undefined,
    title: task.about?.title || undefined,
    description: task.about?.description || undefined,
    redact: task.about?.redact ? "y" : undefined,
    reward_id: task.about?.rewardId || undefined,
    reward_redeemed: task.about?.redeemedDatetime || undefined,
    initiative: task.promptResponse?.initiative ? "y" : undefined,
    prompt_response_context: task.promptResponse?.context || undefined,
    task_response_datetime: task.response?.datetime || undefined,
    task_response_text: task.response?.text || undefined,
    task_response_media_description:
      task.response?.mediaDescription || undefined,
    feedback_datetime: task.feedback?.datetime || undefined,
    feedback_text: task.feedback?.text || undefined,
    feedback_achievements: task.feedback?.achievements || undefined,
    feedback_points: task.feedback?.points,
  };
  return data;
};

type ChangeMode = "create" | "update" | "replace";

export const useChangeTasks = (): {
  changeTasksStatus: RequestStatus;
  changeTasks: (task: Task, changeMode: ChangeMode) => Promise<void>;
} => {
  const [status, setStatus] = useState<RequestStatus>(null);

  const changeTasks = async (task: Task, changeMode: ChangeMode) => {
    const url = `${process.env.REACT_APP_BEHAVE_API_URL}/tasks`;
    const taskData = formatTaskData(task);
    const data = {
      task: taskData,
    };

    try {
      setStatus("waiting");
      switch (changeMode) {
        case "create":
          await axios.post(url, data);
          break;
        case "update":
          await axios.patch(url, data);
          break;
        case "replace":
          await axios.put(url, data);
          break;
        default:
          throw new Error("changeMode not recognized");
      }
      setStatus("success");
    } catch (error) {
      console.error("failed to change tasks:", error);
      setStatus("error");
    }
  };
  return { changeTasksStatus: status, changeTasks };
};
