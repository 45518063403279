import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faPaperPlane,
  faHeart as faHeartSolid,
  faPenToSquare,
  faShuffle,
} from "@fortawesome/free-solid-svg-icons";

import SendPromptPage from "components/sendPrompt/SendPromptPage";
import HomePage from "../home/HomePage";
import EditTasksPage from "../editTasks/EditTasksPage";
import Login from "./Login";
import FooterButton from "./FooterButton";
import { Authorization } from "hooks/useAuth";
import { useRewards } from "hooks/useRewards";
import { useTasks, derivePoints } from "hooks/useTasks";
import GenerateTasksPage from "components/generateTasks/GenerateTasksPage";
import { Page } from "util/typesAlt";

const App = () => {
  const [auth, setAuth] = useState<Authorization>("guest");
  const [page, setPage] = useState<Page | null>(null);

  const { tasks, fetchTasks } = useTasks();
  const { rewards } = useRewards();
  const points = derivePoints(tasks, rewards);

  const handleSetPage = (page: Page) => {
    setPage(page);
    localStorage.setItem("page", page);
  };

  useEffect(() => {
    const savedPage = localStorage.getItem("page");
    if (savedPage !== null) {
      const newPage = savedPage as Page;
      setPage(newPage);
    } else {
      setPage("home");
    }
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className="bg-gray-975 flex items-center px-3 h-12">
        <div className="flex-1">
          <span
            className="text-2xl text-gray-200 select-none"
            onClick={() => setPage("home")}
          >
            Behave
          </span>
          <span className="text-sm ml-3 text-gray-400">
            For Andrea
            <FontAwesomeIcon className="text-xs ml-1" icon={faHeart} />
          </span>
        </div>
        <Login setAuth={setAuth} />
      </div>
      <div className="flex-1 bg-gray-925 overflow-y-scroll scrollbar-themed">
        <div className="h-full w-full">
          {page === "home" && (
            <div className="max-w-2xl ml-auto mr-auto">
              <HomePage
                points={points}
                rewards={rewards}
                tasks={tasks}
                disableRedact={auth !== "guest"}
              />
            </div>
          )}
          {page === "sendPrompt" && (
            <div className="max-w-2xl ml-auto mr-auto">
              <SendPromptPage auth={auth} />
            </div>
          )}
          {page === "editTasks" && (
            <EditTasksPage
              tasks={tasks}
              fetchTasks={fetchTasks}
              rewards={rewards}
            />
          )}
          {page === "generateTasks" && <GenerateTasksPage setPage={setPage} />}
        </div>
      </div>
      <div className="bg-gray-800 h-14 flex items-center gap-2 pl-5 pr-5 border-t border-gray-950">
        <div className="flex-1">
          <FooterButton
            icon={faHeartSolid}
            isCurrent={page === "home"}
            handlePress={() => handleSetPage("home")}
          />
        </div>
        <div className="flex-1">
          <FooterButton
            icon={faPaperPlane}
            isCurrent={page === "sendPrompt"}
            handlePress={() => handleSetPage("sendPrompt")}
          />
        </div>
        {auth === "admin" && (
          <>
            <div className="flex-1 border border-red-500 rounded">
              <FooterButton
                icon={faPenToSquare}
                isCurrent={page === "editTasks"}
                handlePress={() => handleSetPage("editTasks")}
              />
            </div>
            <div className="flex-1 border border-red-500 rounded">
              <FooterButton
                icon={faShuffle}
                isCurrent={page === "generateTasks"}
                handlePress={() => handleSetPage("generateTasks")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;
