import { useState } from "react";
import axios from "axios";

import { RequestStatus, Task, TaskParam } from "../util/typesAlt";

interface Message {
  role: "user" | "system";
  content: string;
}

// Useful in cases where a consistent ordering is desired
export const TASK_PARAMS: TaskParam[] = [
  { name: "Sensuality" },
  { name: "Explicitness" },
  { name: "Physicality" },
  { name: "Creativity" },
  { name: "Vulnerability" },
  { name: "Duration" },
  { name: "Contextual Dependence" },
  { name: "Intensity" },
];

const MODEL = "meta-llama/llama-3-70b-instruct";

const generateMessages = (
  params: TaskParam[],
  context = "",
  additionalRequirements = ""
): Message[] => {
  let paramLines: string[] = [];
  for (let param of params) {
    if (param.value !== undefined) {
      paramLines.push(`${param.name}: ${param.value}`);
    }
  }
  let paramText = paramLines.join("\n");
  if (paramLines.length === 0) {
    paramText = "[No parameters specified]";
  }

  let contextText = "";
  if (context !== "") {
    contextText = `\n\nThe tasks must be suitable for the following context (to a degree specified by Contextual Dependence param): ${context}`;
  }

  let additionalRequirementsText = "";
  if (additionalRequirements !== "") {
    additionalRequirementsText = `\n\nThe tasks must satisfy the following additional requirements: ${additionalRequirements}`;
  }

  return [
    {
      role: "system",
      content: `
You are a loving partner with a dominant side, working to develop an app called Behave that you can play with your long-distance partner. The goal of Behave is to train your partner to do what you say, and to send arousing content to you whenever you want. The rules of Behave are as follows:
1. You send your partner a prewritten prompt at a time of your choosing, along the lines of "Behave: Respond in Signal with stoplight and relevant context".
2. She responds red, yellow or green. f yellow or green, she also sends where she is (e.g. "office", "park", "at home") and any other context that's relevant (e.g. "with friends" or "feeling low energy")
3. If yellow or green, you respond in Signal with a task. If yellow, the task might be along the lines of "drink a glass of water", "send a picture of something beautiful you see",  "describe something you're grateful for", "tell me something you like about me". The general idea is something a) not explicitly sexual, b) fun or positive. If green, the task is explicitly sexual. It might involve actions ("touch yourself and tell me how it feels"), describing something ("tell me about something you fantasize about"), lewd pics or videos, or any other task I can think of.
4. She either sends back "pass", or completes the task and sends back text, audio, picture or video proof of completion (format generally specified in task, e.g. "send me a picture of...").
5. You then send back qualitative feedback (genuine appraisal and also praise) and quantitative number of points. She can eventually redeem points for prizes, like you buying her a solo sushi date, or a sex toy, or a smutty novel.',
`,
    },
    {
      role: "system",
      content: `
You plan to include an admin console for the app that will let you select between a list of tasks that you've written and loaded into the app ahead of time, so that instead of having to come up with a good task on the spot you can pick one that's suitable for any particular moment. Currently you are composing the data that will populate that list, so you should ONLY respond in the following JSON RESPONSE FORMAT:
[{"title": "<some task title>", "description": "<the description for the task>", "explicit": <true if explicit, false if not explicit>}]',
`,
    },
    {
      role: "user",
      content: `The following is a set of dimensions that describe the space of all possible tasks for your partner to perform for you:
1. Sensuality (0-100): Measures the level of physical or sensual stimulation involved in the task. Tasks with low sensuality might involve non-physical activities, while high sensuality tasks might involve explicit sexual acts.
2. Explicitness (0-100): Represents the level of explicit content or language required in the task. Low explicitness tasks might involve vague or suggestive language, while high explicitness tasks might require detailed descriptions or explicit images.
3. Physicality (0-100): Determines the level of physical activity or effort required to complete the task. Low physicality tasks might involve minimal movement, while high physicality tasks might require more energetic or dynamic actions.
4. Creativity (0-100): Measures the level of imagination or creative expression required in the task. Low creativity tasks might involve simple, straightforward actions, while high creativity tasks might require more artistic or innovative responses.
5. Vulnerability (0-100): Represents the level of emotional exposure or vulnerability required in the task. Low vulnerability tasks might involve lighthearted or superficial interactions, while high vulnerability tasks might require more intimate or personal revelations.
6. Duration (0-100): Represents the estimated time required to complete the task. Low duration tasks might involve quick, simple actions, while high duration tasks might require longer, more involved activities.
7. Contextual dependence (0-100): Determines the level of dependence on the participant's current situation or environment. Low contextual dependence tasks might involve tasks that can be completed anywhere, while high contextual dependence tasks might require specific settings or circumstances.
8. Intensity (0-100): Measures the level of mental or emotional arousal, challenge, or discomfort required to complete the task. Low intensity tasks might be relaxing, calming, or straightforward, while high intensity tasks might be thrilling, uncomfortable, or push boundaries.

Respond with 10 distinct tasks that satisfy the following parameters:
${paramText}

If a dimension has not been specified the tasks you respond with may have any value for that dimension.${contextText}${additionalRequirementsText}

Additional guidelines:
- Task titles should be a brief and simple summation of the task. They should NOT be clever or poetic or alliterative. For example, a task with description "Take a nude selfie in the bathroom mirror" might have the title "Nude bathroom selfie".
- Both titles and descriptions should be casual and matter of fact, rather than poetic, silly or clever.

Respond using ONLY the specified JSON RESPONSE FORMAT with NO additional text. Do NOT include any additional text; the first character must be "[" and the last must be "]"`,
    },
  ];
};

interface GeneratedTaskRaw {
  title: string;
  description: string;
  explicit: boolean;
}

// Throws errors on failure to parse
const parseResponseMessage = (responseMessage: string): Task[] | null => {
  try {
    const parsedJson = JSON.parse(responseMessage);
    for (let rawTask of parsedJson) {
      if (
        rawTask.title === undefined ||
        rawTask.description === undefined ||
        rawTask.explicit === undefined
      ) {
        throw new Error("improperly formatted raw generated task");
      }
    }
    const rawTasks = parsedJson as GeneratedTaskRaw[];
    return rawTasks.map((rawTask): Task => {
      return {
        about: {
          color: rawTask.explicit ? "green" : "yellow",
          title: rawTask.title,
          description: rawTask.description,
        },
      };
    });
  } catch (error: any) {
    throw new Error(`failed to parse response message: ${error.message}`);
  }
};

export const useGenerateTasks = (): {
  generateTasksStatus: RequestStatus;
  getGeneratedTasks: (
    taskParams: TaskParam[],
    context?: string,
    additionalRequirements?: string
  ) => Promise<Task[] | null>;
} => {
  const [generateTasksStatus, setGenerateTasksStatus] =
    useState<RequestStatus>(null);
  // const [generatedTasks, setGeneratedTasks] = useState<Task[] | null>(null);

  const getGeneratedTasks = async (
    taskParams: TaskParam[],
    context = "",
    additionalRequirements = ""
  ): Promise<Task[] | null> => {
    // This REALLY should be handled in the backend to protect the api key, but Netlify Functions enforces a 10s timeout for free accounts
    const openrouterApiKey = process.env.REACT_APP_OPENROUTER_API_KEY;
    if (openrouterApiKey === undefined) {
      setGenerateTasksStatus("error");
      return null;
    }

    const url = "https://openrouter.ai/api/v1/chat/completions";
    const messages = generateMessages(
      taskParams,
      context,
      additionalRequirements
    );
    const headers = {
      Authorization: `Bearer ${openrouterApiKey}`,
      "Content-Type": "application/json",
    };
    const data = {
      model: MODEL,
      messages,
      provider: {
        order: ["Together", "Lepton", "OctoAI"],
      },
    };

    try {
      setGenerateTasksStatus("waiting");
      const body = await axios.post(url, data, { headers });
      const responseChoice = body.data?.choices[0];
      const responseMessage = responseChoice.message.content;

      const generatedTasks = parseResponseMessage(responseMessage);
      setGenerateTasksStatus("success");
      return generatedTasks;
    } catch (error) {
      console.error("failed to generate tasks:", error);
      setGenerateTasksStatus("error");
      return null;
    }
  };
  return { generateTasksStatus, getGeneratedTasks };
};
