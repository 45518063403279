import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbTack,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import TaskCard from "components/common/TaskCard";
import {
  Task,
  TaskParam,
  PinnableTask,
  Page,
  EditTasksState,
  GenerateTasksControlsState,
} from "util/typesAlt";
import { makeCopy } from "util/util";
import { TASK_PARAMS, useGenerateTasks } from "hooks/useGenerateTasks";

const makePinnable = (tasks: Task[]): PinnableTask[] => {
  return tasks.map((task) => {
    return { task, pinned: false };
  });
};

const GenerateTasksPage = ({ setPage }: { setPage: (page: Page) => void }) => {
  const [expandControls, setExpandControls] = useState<boolean>(true);

  const [taskParams, setTaskParams] = useState<TaskParam[]>(TASK_PARAMS);
  const [context, setContext] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState("");
  const [allPinnable, setAllPinnable] = useState<PinnableTask[] | null>(null);

  const { generateTasksStatus, getGeneratedTasks } = useGenerateTasks();

  const saveGeneratedTasksState = (toSave: PinnableTask[] | null = null) => {
    if (toSave === null) {
      toSave = allPinnable;
    }
    if (toSave === null) {
      return;
    }
    localStorage.setItem("generatedTasksState", JSON.stringify(toSave));
  };

  const generateTasks = async () => {
    // Filter unpinned tasks first so it's clear something's happening
    const oldPinned =
      allPinnable === null ? [] : allPinnable.filter((task) => task.pinned);
    setAllPinnable(oldPinned);

    const newTasks = await getGeneratedTasks(
      taskParams,
      context,
      additionalRequirements
    );
    if (newTasks === null) {
      return;
    }
    const newPinnable = makePinnable(newTasks);

    const newAllPinnable = oldPinned.concat(newPinnable);
    setAllPinnable(newAllPinnable);
    saveGeneratedTasksState(newAllPinnable);
  };

  const togglePinned = (index: number) => {
    if (allPinnable === null) {
      return;
    }
    let copy = makeCopy(allPinnable);
    copy[index].pinned = !copy[index].pinned;
    setAllPinnable(copy);
    saveGeneratedTasksState(copy);
  };

  const setTaskParam = (index: number, value: number | undefined) => {
    if (index >= taskParams.length) {
      return;
    }
    const copy = makeCopy(taskParams);
    copy[index].value = value;
    setTaskParams(copy);
  };

  const loadGeneratedTasksControlsState = () => {
    const stateJson = localStorage.getItem("generateTasksControlsState");
    if (stateJson === null) {
      return;
    }
    try {
      const state = JSON.parse(stateJson) as GenerateTasksControlsState;
      if (state.context !== "") {
        setContext(state.context);
      }
      if (state.additionalRequirements !== "") {
        setAdditionalRequirements(state.additionalRequirements);
      }
      let taskParamsBlank = true;
      for (let name in state.taskParams) {
        if (state.taskParams[name].value !== undefined) {
          taskParamsBlank = false;
        }
      }
      if (!taskParamsBlank) {
        setTaskParams(state.taskParams);
      }
    } catch (error) {
      console.error("failed to load generate tasks controls state:", error);
    }
  };

  const loadGeneratedTasksState = () => {
    const stateJson = localStorage.getItem("generatedTasksState");
    if (stateJson === null) {
      return;
    }
    try {
      const state = JSON.parse(stateJson) as PinnableTask[];
      if (state.length > 0) {
        setAllPinnable(state);
      }
    } catch (error) {
      console.error("failed to load generated tasks state:", error);
    }
  };

  useEffect(() => {
    loadGeneratedTasksControlsState();
    loadGeneratedTasksState();
  }, []);

  useEffect(() => {
    const state: GenerateTasksControlsState = {
      context,
      additionalRequirements,
      taskParams,
    };
    localStorage.setItem("generateTasksControlsState", JSON.stringify(state));
  }, [context, additionalRequirements, taskParams]);

  return (
    <div className="flex flex-col h-full">
      {generateTasksStatus === "waiting" ? (
        <div className="text-gray-200 text-lg mx-3 mt-3">Generating...</div>
      ) : generateTasksStatus === "error" ? (
        <div className="text-red-500 text-lg mx-3 mt-3">
          Error generating tasks
        </div>
      ) : (
        <></>
      )}
      <div className="flex-1">
        {allPinnable !== null && (
          <div className="px-3 pb-3">
            {allPinnable.map((pinnable, i) => (
              <div key={i} className="flex items-center mt-4">
                <button
                  className="bg-gray-800 px-2 py-1 rounded mr-2 active:bg-gray-950 active:text-gray-800"
                  onClick={() => {
                    if (pinnable.task.about !== undefined) {
                      const editTasksState: EditTasksState = {
                        aboutDraft: { ...pinnable.task.about, exclude: true },
                      };
                      localStorage.setItem(
                        "editTasksState",
                        JSON.stringify(editTasksState)
                      );

                      setPage("editTasks");
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className={`bg-gray-800 px-2 py-1 rounded mr-2 active:bg-gray-950 active:text-gray-800 ${
                    pinnable.pinned ? "text-gray-200" : ""
                  }`}
                  onClick={() => togglePinned(i)}
                >
                  <FontAwesomeIcon icon={faThumbTack} />
                </button>
                <div className="flex-1">
                  <TaskCard task={pinnable.task} disableRedact={true} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className={`sticky bottom-0 flex flex-col ${
          expandControls ? "min-h-[50vh]" : "max-h-[30vh]"
        }`}
      >
        <div className="h-8 bg-gray-950 flex text-gray-500 select-none items-center justify-center relative">
          <div onClick={() => setExpandControls(!expandControls)}>
            <FontAwesomeIcon
              icon={expandControls ? faChevronDown : faChevronUp}
            />
            <span className="ml-1">Controls</span>
          </div>
          <div className="absolute left-1">
            <button
              className="px-2 bg-blue-800 text-gray-300 rounded active:bg-gray-950"
              type="submit"
              onClick={() => {
                setExpandControls(false);
                generateTasks();
              }}
            >
              Generate
            </button>
          </div>
        </div>
        <div className="flex-1 bg-gray-900 text-gray-200 overflow-y-scroll overflow-x-hidden p-3">
          <form className="text-gray-500">
            <div className="mt-3">
              <label>
                Context
                <input
                  className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
                  type="text"
                  value={context}
                  onChange={(event) => setContext(event.target.value)}
                />
              </label>
            </div>
            <div className="mt-3">
              <label>
                Additional Requirements
                <input
                  className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
                  type="text"
                  value={additionalRequirements}
                  onChange={(event) =>
                    setAdditionalRequirements(event.target.value)
                  }
                />
              </label>
            </div>

            {taskParams.map((taskParam, i) => (
              <div key={i} className="mt-4">
                <label>
                  {taskParam.name}
                  <input
                    className="bg-gray-800 rounded border border-gray-600 ml-1"
                    type="checkbox"
                    checked={taskParam.value !== undefined}
                    onChange={(event) => {
                      const newValue = event.target.checked ? 50 : undefined;
                      setTaskParam(i, newValue);
                    }}
                  />
                  <input
                    className="w-full border"
                    type="range"
                    min="0"
                    max="100"
                    disabled={taskParam.value === undefined}
                    value={
                      taskParam.value === undefined
                        ? "0"
                        : taskParam.value.toString()
                    }
                    onChange={(event) =>
                      setTaskParam(i, parseInt(event.target.value))
                    }
                  />
                </label>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
};

export default GenerateTasksPage;
