import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

import { Authorization, useAuth } from "hooks/useAuth";

const Login = ({ setAuth }: { setAuth: (auth: Authorization) => void }) => {
  const { auth, submitPassword } = useAuth();

  useEffect(() => {
    setAuth(auth);
  }, [auth, setAuth]);
  return (
    <div
      className="text-gray-100"
      onClick={() => {
        const password = prompt("Enter password:");
        if (password === "" || password === null) {
          return;
        }
        submitPassword(password, true);
      }}
    >
      {auth === "guest" ? (
        <div className="text-gray-50">
          <FontAwesomeIcon icon={faLock} />
        </div>
      ) : (
        <div className={auth === "admin" ? "text-red-500" : "text-gray-400"}>
          <FontAwesomeIcon icon={faLockOpen} />
        </div>
      )}
    </div>
  );
};

export default Login;
