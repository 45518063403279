import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

// TODO: Refactor to use button & focus

const FooterButton = ({
  icon,
  isCurrent,
  handlePress,
}: {
  icon: IconDefinition;
  isCurrent: boolean;
  handlePress: () => void;
}) => {
  const [pressed, setPressed] = useState(false);

  const pressedColors = "bg-gray-950 text-gray-800";
  const currentColors = "bg-gray-800 text-gray-200";
  const normalColors = "text-gray-950";

  const handleDown = () => {
    setPressed(true);
    handlePress();
  };

  const handleUp = () => setPressed(false);

  return (
    <div
      onMouseDown={handleDown}
      onMouseUp={handleUp}
      className={`py-1 rounded text-3xl ${
        pressed ? pressedColors : isCurrent ? currentColors : normalColors
      }`}
    >
      <FontAwesomeIcon className="w-full" icon={icon} />
    </div>
  );
};

export default FooterButton;
