import { VictoryPie } from "victory";

const ProgressChart = ({
  progress,
  goal,
}: {
  progress: number;
  goal: number;
}) => {
  let filled = progress;
  let goalMet = progress >= goal;
  if (goalMet) {
    filled = goal;
  }

  let increment = goal <= 50 ? 1 : 10;
  let data = [];

  // Add "full" progress increments
  for (let i = 0; i < Math.floor(filled / increment); i++) {
    data.push({ y: increment, isProgress: true });
  }

  // Add a partial progress increment if applicable
  const partial = filled % increment;
  if (partial !== 0) {
    data.push({ y: partial, isProgress: true });
    data.push({ y: increment - partial, isProgress: false });
  }

  // Fill in the remaining increments
  for (let i = Math.ceil(progress / increment); i < goal / increment; i++) {
    data.push({ y: increment, isProgress: false });
  }

  return (
    <div className={`relative ${goalMet ? "text-gray-950" : "text-gray-200"}`}>
      <div className="absolute">
        {goalMet && (
          <VictoryPie
            radius={() => 85}
            padAngle={() => 1}
            colorScale={["#2196f3"]} // blue-500
            style={{ labels: { display: "none" } }}
            data={[{ y: 1 }]}
          />
        )}
      </div>
      <div className="absolute w-full h-full flex items-center">
        <div className="ml-auto mr-auto text-center">
          <div className="text-2xl leading-3 mb-4">{progress}</div>
          <div className="leading-4">{goal}</div>
        </div>
        <div className="absolute left-1/3 right-1/3 flex items-center">
          <div
            className={`w-full border-b ${
              goalMet ? "border-gray-950" : "border-gray-200"
            }`}
          ></div>
        </div>
      </div>
      <VictoryPie
        innerRadius={({ datum }) => (datum.isProgress ? 90 : 150)}
        padAngle={() => 1}
        colorScale={["#2196f3"]} // blue-500
        style={{ labels: { display: "none" } }}
        data={data}
      />
    </div>
  );
};

export default ProgressChart;
