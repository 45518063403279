import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  EditTasksState,
  PromptResponse,
  Reward,
  Task,
  TaskAbout,
  TaskFeedback,
  TaskResponse,
} from "util/typesAlt";
import TaskCard from "components/common/TaskCard";
import { makeCopy, formatDateIso, isFalsy } from "util/util";
import { useChangeTasks } from "hooks/useChangeTasks";
import PromptResponseForm from "components/editTasks/PromptResponseForm";
import TaskAboutForm from "components/editTasks/TaskAboutForm";
import TaskFeedbackForm from "components/editTasks/TaskFeedbackForm";
import TaskResponseForm from "components/editTasks/TaskResponseForm";

type FormPage = "about" | "promptResponse" | "response" | "feedback";

const EditTasksPage = ({
  tasks,
  fetchTasks,
  rewards,
}: {
  tasks: Task[] | null;
  fetchTasks: () => Promise<void>;
  rewards: Reward[] | null;
}) => {
  const [expandEdit, setExpandEdit] = useState<boolean>(true);

  const [editingTaskIndex, setEditingTaskIndex] = useState<number | null>(null);
  const [aboutDraft, setAboutDraft] = useState<TaskAbout>({});
  const [promptResponseDraft, setPromptResponseDraft] =
    useState<PromptResponse>({});
  const [responseDraft, setResponseDraft] = useState<TaskResponse>({});
  const [feedbackDraft, setFeedbackDraft] = useState<TaskFeedback>({});
  const [formPage, setFormPage] = useState<FormPage>("about");

  const { changeTasksStatus, changeTasks } = useChangeTasks();

  const editingTask =
    tasks !== null &&
    editingTaskIndex !== null &&
    editingTaskIndex < tasks.length
      ? tasks[editingTaskIndex]
      : null;

  const clearTask = () => {
    setEditingTaskIndex(null);
    setAboutDraft({});
    setPromptResponseDraft({});
    setResponseDraft({});
    setFeedbackDraft({});
  };

  const handleCreate = async () => {
    const now = new Date();
    let newTask: Task = {
      createdDatetime: formatDateIso(now),
      about: aboutDraft,
      promptResponse: promptResponseDraft,
      response: responseDraft,
      feedback: feedbackDraft,
    };

    if (newTask.feedback !== undefined) {
      if (!isFalsy(newTask.feedback) && !newTask.feedback.datetime) {
        newTask.feedback.datetime = formatDateIso(now);
      }
    }

    if (
      newTask.about?.rewardId !== undefined &&
      !newTask.about.redeemedDatetime
    ) {
      newTask.about.redeemedDatetime = formatDateIso(now);
    }

    await changeTasks(newTask, "create");
    clearTask();
    fetchTasks();
  };

  const handleEdit = async () => {
    if (editingTask === null) {
      return;
    }
    let oldTask = makeCopy(editingTask);

    // Override any properties for which new properties exists
    let newTask: Task = {
      ...oldTask,
      about: {
        ...oldTask.about,
        ...aboutDraft,
      },
      promptResponse: {
        ...oldTask.promptResponse,
        ...promptResponseDraft,
      },
      response: {
        ...oldTask.response,
        ...responseDraft,
      },
      feedback: {
        ...oldTask.feedback,
        ...feedbackDraft,
      },
    };

    if (newTask.feedback !== undefined) {
      if (!isFalsy(newTask.feedback) && !newTask.feedback.datetime) {
        const now = new Date();
        newTask.feedback.datetime = formatDateIso(now);
      }
    }

    await changeTasks(newTask, "replace");
    fetchTasks();
  };

  const handleSelectEdit = (taskIndex: number) => {
    const confirmed = window.confirm(
      "You are about to discard a draft. Are you sure?"
    );
    if (!confirmed) {
      return;
    }
    if (taskIndex === editingTaskIndex) {
      setEditingTaskIndex(null);
    } else {
      setEditingTaskIndex(taskIndex);
    }
  };

  // Update props for child form components when a new task is selected
  useEffect(() => {
    if (editingTask === null) {
      setAboutDraft({});
      setPromptResponseDraft({});
      setResponseDraft({});
      setFeedbackDraft({});
    } else {
      setAboutDraft(editingTask.about === undefined ? {} : editingTask.about);
      setPromptResponseDraft(
        editingTask.promptResponse === undefined
          ? {}
          : editingTask.promptResponse
      );
      setResponseDraft(
        editingTask.response === undefined ? {} : editingTask.response
      );
      setFeedbackDraft(
        editingTask.feedback === undefined ? {} : editingTask.feedback
      );
    }
  }, [editingTask]);

  useEffect(() => {
    if (changeTasksStatus === "error") {
      alert(
        editingTaskIndex === null
          ? "Failed to create task"
          : "Failed to update task"
      );
    }
  }, [changeTasksStatus, editingTaskIndex]);

  const loadEditTasksState = () => {
    const stateJson = localStorage.getItem("editTasksState");
    if (stateJson === null) {
      return;
    }
    try {
      const state = JSON.parse(stateJson) as EditTasksState;
      if (isFalsy(state)) {
        return;
      }
      setEditingTaskIndex(state.editingTaskIndex || null);
      setAboutDraft(state.aboutDraft || {});
      setPromptResponseDraft(state.promptResponseDraft || {});
      setResponseDraft(state.responseDraft || {});
      setFeedbackDraft(state.feedbackDraft || {});
    } catch (error) {
      console.error("failed to load edit tasks state:", error);
    }
  };

  useEffect(() => {
    loadEditTasksState();
  }, []);

  useEffect(() => {
    const state: EditTasksState = {
      editingTaskIndex,
      aboutDraft,
      promptResponseDraft,
      responseDraft,
      feedbackDraft,
    };
    if (!isFalsy(state)) {
      localStorage.setItem("editTasksState", JSON.stringify(state));
    }
  }, [
    editingTaskIndex,
    aboutDraft,
    promptResponseDraft,
    responseDraft,
    feedbackDraft,
  ]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        {tasks !== null && (
          <div className="px-3 pb-3">
            {tasks.map((task, i) => (
              <div key={i} className="flex items-center mt-4">
                <button
                  className={`bg-gray-800 px-2 py-1 rounded mr-2 active:bg-gray-950 active:text-gray-800 ${
                    editingTaskIndex === i ? "text-gray-200" : ""
                  }`}
                  onClick={() => handleSelectEdit(i)}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <div
                  className={`flex-1 ${
                    editingTaskIndex === i
                      ? "border border-blue-500 p-1"
                      : task.about?.exclude
                      ? "border border-dashed border-red-500 p-1"
                      : ""
                  }`}
                >
                  <TaskCard
                    task={task}
                    rewards={rewards}
                    disableRedact={true}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className={`sticky bottom-0 flex flex-col ${
          expandEdit ? "min-h-[50vh]" : "max-h-[30vh]"
        }`}
      >
        <div
          className="h-8 bg-gray-950 flex text-gray-500 select-none items-center justify-center gap-2"
          onClick={() => setExpandEdit(!expandEdit)}
        >
          <FontAwesomeIcon icon={expandEdit ? faChevronDown : faChevronUp} />
          <div>{editingTask === null ? "New Task" : "Editing Task"}</div>
        </div>
        <div className="flex-1 bg-gray-900 text-gray-200 overflow-y-scroll overflow-x-hidden px-3 pb-3">
          <div className="text-gray-950 bg-gray-800 rounded flex h-7 my-2 select-none">
            <button
              className={`flex-1 rounded-l ${
                formPage === "about" ? "bg-gray-950 text-gray-300" : ""
              }`}
              type="submit"
              onClick={() => setFormPage("about")}
            >
              About
            </button>
            <button
              className={`flex-1 ${
                formPage === "promptResponse" ? "bg-gray-950 text-gray-300" : ""
              }`}
              type="submit"
              onClick={() => setFormPage("promptResponse")}
            >
              Prompt R.
            </button>
            <button
              className={`flex-1 ${
                formPage === "response" ? "bg-gray-950 text-gray-300" : ""
              }`}
              type="submit"
              onClick={() => setFormPage("response")}
            >
              Response
            </button>
            <button
              className={`flex-1 rounded-r ${
                formPage === "feedback" ? "bg-gray-950 text-gray-300" : ""
              }`}
              type="submit"
              onClick={() => setFormPage("feedback")}
            >
              Feedback
            </button>
          </div>
          {formPage === "about" && (
            <TaskAboutForm
              about={aboutDraft}
              handleAboutChange={setAboutDraft}
              rewards={rewards}
            />
          )}
          {formPage === "promptResponse" && (
            <PromptResponseForm
              promptResponse={promptResponseDraft}
              handlePromptResponseChange={setPromptResponseDraft}
            />
          )}
          {formPage === "response" && (
            <TaskResponseForm
              response={responseDraft}
              handleResponseChange={setResponseDraft}
            />
          )}
          {formPage === "feedback" && (
            <TaskFeedbackForm
              feedback={feedbackDraft}
              handleFeedbackChange={setFeedbackDraft}
            />
          )}
          <div className="mt-3">
            {editingTask === null ? (
              <button
                className="py-1 px-2 bg-blue-800 text-gray-300 rounded active:bg-gray-950"
                type="submit"
                onClick={handleCreate}
              >
                Create Task
              </button>
            ) : (
              <button
                className="py-1 px-2 bg-blue-800 text-gray-300 rounded active:bg-gray-950"
                type="submit"
                onClick={handleEdit}
              >
                Update Task
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTasksPage;
