import { useState } from "react";

import { useSendPrompt } from "hooks/useSendPrompt";
import { Authorization } from "hooks/useAuth";

const SendPromptPage = ({ auth }: { auth: Authorization }) => {
  const [notTest, setNotTest] = useState<string>("false");
  const [custom, setCustom] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("I'd like to behave <3");
  const { promptStatus, sendPrompt } = useSendPrompt();
  return (
    <div className="p-3">
      {auth === "admin" && (
        <div className="border border-red-500 rounded p-2 text-gray-200 mb-3">
          <div className="text-lg">Send Prompt</div>
          <div className="mt-3 text-white">
            <select
              className="bg-gray-800 rounded py-0 px-1text-gray-200"
              value={notTest}
              onChange={(event) => {
                setNotTest(event.target.value);
              }}
            >
              <option value="true">Send to Andrea</option>
              <option value="false">Send to Russell</option>
            </select>
          </div>

          <div className="mt-3">
            <label>
              Custom message?
              <input
                className="bg-gray-800 rounded border border-gray-600 ml-2"
                type="checkbox"
                checked={custom}
                onChange={(event) => setCustom(event.target.checked)}
              />
            </label>
          </div>
          {custom && (
            <div className="mt-3">
              <input
                className="bg-gray-800 rounded py-0 px-1"
                type="text"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </div>
          )}
          <div className="mt-3">
            <button
              className="py-1 px-2 bg-blue-800 rounded active:bg-gray-950"
              onClick={() =>
                sendPrompt(custom ? message : undefined, notTest === "true")
              }
            >
              Send
            </button>
          </div>
          {promptStatus !== null && (
            <div className="mt-3">{`[${promptStatus}]`}</div>
          )}
        </div>
      )}
      <div className="text-gray-200">
        <div className="text-lg">Request Task</div>
        <div className="mt-3">
          <input
            className="bg-gray-800 rounded py-0 px-1"
            type="text"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </div>
        <div className="mt-3">
          <button
            className="py-1 px-2 bg-blue-800 rounded active:bg-gray-950"
            onClick={() => {
              if (auth !== "guest") {
                sendPrompt(message, notTest === "true");
              } else {
                alert("Gotta be logged in :-)");
              }
            }}
          >
            Send
          </button>
        </div>
        {promptStatus !== null && (
          <div className="mt-3">{`[${promptStatus}]`}</div>
        )}
      </div>
    </div>
  );
};

export default SendPromptPage;
