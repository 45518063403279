import { PromptResponse } from "util/typesAlt";
import { emptyToUndefined } from "util/util";

const PromptResponseForm = ({
  promptResponse,
  handlePromptResponseChange,
}: {
  promptResponse: PromptResponse;
  handlePromptResponseChange: (promptResponse: PromptResponse) => void;
}) => {
  const handleInputChange = (name: string, value: string | boolean) => {
    handlePromptResponseChange({
      ...promptResponse,
      [name]: emptyToUndefined(value),
    });
  };

  return (
    <form className="text-gray-500">
      <div className="mt-3">
        <label>
          Initiative
          <input
            className="bg-gray-800 rounded border border-gray-600 ml-1"
            type="checkbox"
            checked={promptResponse.initiative || false}
            onChange={(event) =>
              handleInputChange("initiative", event.target.checked)
            }
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Context
          <input
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            type="text"
            value={promptResponse.context || ""}
            onChange={(event) =>
              handleInputChange("context", event.target.value)
            }
          />
        </label>
      </div>
    </form>
  );
};

export default PromptResponseForm;
